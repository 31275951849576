import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout";
import * as styles from "./404.module.css";
import {Helmet} from 'react-helmet';

const NotFoundPage = () => {
  return (
    <Layout>
      <Helmet>
        <title>Colin Takeo - here be dragons</title>
      </Helmet>
      <div className={`contentLeft ${styles.alignEnd} ${styles.contentLeft}`}>
        <p className="light lifted-div">The dragons stir in their slumber... &#x1F409;</p>
      </div>
      <div className={`contentRight ${styles.contentRight}`}>
        <Link className={`lifted-div icy ${styles.homeBut}`} to="/">Go home</Link>
      </div>
    </Layout>
  )
}

export default NotFoundPage;
